export const FirebaseConfig = {
	"projectId": "heyjag-ride",
	"appId": "1:208252531399:web:92195d44a0c2c8799e44e2",
	"databaseURL": "https://heyjag-ride-default-rtdb.firebaseio.com",
	"storageBucket": "heyjag-ride.appspot.com",
	"locationId": "us-east4",
	"apiKey": "AIzaSyBoozc9cTzkz-uXFrJkmhfNWWfHAMcwR6k",
	"authDomain": "heyjag-ride.firebaseapp.com",
	"messagingSenderId": "208252531399",
	"measurementId": "G-CQJFDZ0B8Z"
};